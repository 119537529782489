import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './index.css';
import '../Pages/index.css';



const Footer = () => {
  return (
    <div className='Footer'>
        <div className="reso">
            

        </div>
       
        <div className="lkdll andMany zjhisc">
        Made with care&nbsp;&nbsp;<svg width="16" height="16" viewBox="0 0 64 64" fill="none"><path d="M39.64 40.83L33.87 56.7a1.99 1.99 0 0 1-3.74 0l-5.77-15.87a2.02 2.02 0 0 0-1.2-1.2L7.3 33.88a1.99 1.99 0 0 1 0-3.74l15.87-5.77a2.02 2.02 0 0 0 1.2-1.2L30.12 7.3a1.99 1.99 0 0 1 3.74 0l5.77 15.87a2.02 2.02 0 0 0 1.2 1.2l15.86 5.76a1.99 1.99 0 0 1 0 3.74l-15.87 5.77a2.02 2.02 0 0 0-1.2 1.2z" fill="#FFC017"></path></svg>&nbsp;&nbsp;2024
      </div>
    </div>
  )
}

export default Footer
